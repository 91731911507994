.main {
  height: 100vh;
  background-color: flex;
  padding: 0px;
  margin-top: 10px;
}

.left-container-login {
  background-position: center;
  background-position-x: center;
  background-image: url(../../assets/images/fondo2.svg);
  background-repeat: no-repeat;
}

.logo {
  height: 25px;
}

.right-container-login {
  background-color: #d8e3db;
}
