.view-floating-btn{
    position: fixed;
    display: grid;
    bottom: 2%;
    right: 4%;
    z-index: 2;
}

.view-floating-btn a{
    border-radius: 50%;
    background-color: #f9423a;
    color: var(--bs-white);
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
}

.view-floating-btn div
{
    text-align: center;
    padding-top: 10px;
}