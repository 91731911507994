
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: #444444;
    font-size: 14px;
    background: #f6f4f4;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Nunito", sans-serif;
    color: #777777;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ea6981;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: var(--color-orange);
  }
  #footer .footer-newsletter {
    font-size: 15px;
  }
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    border: 1px solid #f7c2cc;
  }
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 15px;
    padding: 0 22px;
    background: var(--color-orange);
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #d01d3f;
  }
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: var(--color-orange);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .social-links a:hover {
    background: #d01d3f;
    color: #fff;
    text-decoration: none;
  }